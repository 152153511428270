import {
  actions as parentActions,
  mutations as parentMutations,
  state as parentState,
} from '~/sites/shop/store/catalog' // Dědíme původní store

export const state = () => ({
  ...parentState(),
  scrollPosition: 0,
})

export const mutations = {
  ...parentMutations,
  SET_SCROLL_POSITION(state, position) {
    state.scrollPosition = position
  },
}

export const actions = {
  ...parentActions,
  SAVE_SCROLL_POSITION({ commit }) {
    commit('SET_SCROLL_POSITION', window.scrollY)
  },
}
